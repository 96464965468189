import React from "react";
import { CentralManager } from "./CentralManager";
import * as Sentry from "@sentry/react";
import posthog from 'posthog-js';
import Support from "@mui/icons-material/Support";
import MenuBookIcon from '@mui/icons-material/MenuBook';
export interface GlobalOptions {
  font: string,
  theme: string,
  sleep: string,
}

export interface SharedOptions {
  connected: boolean;
  connecting: boolean;
  onDisconnect: () => void;
  onReconnect: () => void;
  cm: CentralManager;
  globalSettings: GlobalOptions;
  onChange: (key: string) => void,
}

export const GlobalSettings = (props: { sharedOptions: SharedOptions, close: () => void, children?: React.ReactNode, tmpShowPoker?: () => void }) => {
  const opt = props.sharedOptions;
  const cm = opt.cm;
  const scene = cm.getCurrentScene();

  const [undoText, setUndoText] = React.useState('loading');
  React.useEffect(() => {
    cm.getUndoRedoInfo().then(({ undo }) => {
      setUndoText(undo || 'none');
    });
  });

  return (
    <>
      <div className="overlay-common text-center">

        {
          opt.connected ||
          <div className="grid grid-cols-1 gap-2 mb-12 mt-4">
            <div className=" text-lg font-bold text-red-500">disconnected</div>
            <div className=" text-red-500">You lost connection to the server. Click below to retry:</div>
            <div className={`button3 ${opt.connecting && 'disabled'}`} onClick={opt.onReconnect}>{opt.connecting ? 'connecting...' : 'reconnect'}</div>
          </div>
        }

        <h2 className="group-header">game options</h2>
        <div className="grid grid-cols-1 gap-2 group">
          <div className="button3" onClick={() => { cm.undoRedo(true) }}><span className="emphasize">undo: {undoText}</span></div>
          {/* TODO: implement redo */}
          {/* <div className="button3" onClick={() => { cm.undoRedo(false) }}><span className="emphasize">redo:</span></div> */}
          {
            scene !== 'table' &&
            <div className="button3 red" onClick={() => returnToTable(cm)}>return back to table</div>
          }
        </div>

        {props.children}


        <h2 className="group-header">global options</h2>
        <div className="grid grid-cols-2 gap-2 group">
          <div className="button3 col-span-full" onClick={() => props.tmpShowPoker?.()}>
            <MenuBookIcon fontSize="small" />&nbsp;
            view poker rules</div>
          <div className="button3" onClick={() => opt.onChange('font')}>font size: {opt.globalSettings.font}</div>
          <div className="button3" onClick={() => opt.onChange('theme')}>theme: {opt.globalSettings.theme}</div>
          <div className="button3 col-span-full" onClick={() => opt.onChange('sleep')}>prevent screen sleep: {opt.globalSettings.sleep}</div>
          <div className="button3 col-span-full" onClick={reportAnIssue}>
            <Support fontSize="small" />&nbsp;
            report an issue</div>
        </div>

        <h2 className="group-header">debugging options</h2>
        <div className="grid grid-cols-2 gap-2 group">
          <div className="button3" onClick={opt.onDisconnect}>disconnect</div>
          <div className="button3" onClick={opt.onReconnect}>re-connect</div>
          <div className="button3" onClick={() => openInspector(cm.props.tableId)}>open inspector</div>
          <div className="button3" onClick={clearLocalStorage}>clear local storage</div>
          <div className="button3" onClick={() => { throw new Error("Intentional error to test sentry.") }}>test sentry</div>
          <div className="col-span-full">connected to table {cm.props.tableId}</div>
        </div>
      </div>

      <div className="button3 w-full" onClick={() => props.close()}>Close</div>
    </>
  )
}

const clearLocalStorage = () => {
  if (confirm('Local storage will be cleared. Are you sure?')) {
    localStorage.clear();
  }
}

const returnToTable = (cm: CentralManager) => {
  if (confirm('Current game will be lost. Are you sure?')) {
    const t = cm.getCurrentTable().copy();
    t.currentGame = null;
    cm.triggerTableUpdate(t, 'discard current game');
  }
}

const reportAnIssue = () => {
  const response = prompt('Please describe the issue you are facing:');
  if (!response) return;

  posthog.capture('issue_reported', { issue: response });
  Sentry.captureFeedback({ message: response });

  alert('Thanks for your feedback! If you would like to provide more info, email contact@vchips.app');
}

const openInspector = (tableId: string) => {
  // open url in a new tab
  window.open(`${window.location.origin}/tools/inspect/${tableId}`, '_blank');
}
