import Assert from "./assert";
import { findPlayer, generateObjectWithKeysAndValue, generateQueueForRound, mergePlayerChipMap, reorderItemAsTheyAppearIn, splitChipIntoInteger } from "./helpers";
import { ChipAmount, PlayerId, PlayerChipMap, Pot, GameRoundName, PlayerAction, PlayerActionOptions } from "./types";
import * as R from 'ramda';

export interface GameSettings {
  smallBlindAmount: ChipAmount;
  bigBlindAmount: ChipAmount;
  defaultStartingAmount: ChipAmount;
  strictMinRaise: boolean;
}

export interface TableInterface {
  players: PlayerId[];
  tableStacks: PlayerChipMap;
  currentGame: Game | null;
  settings: GameSettings;
}

type ActionHistory = Partial<Record<GameRoundName, { player: PlayerId, action: PlayerAction, actionOptions: PlayerActionOptions }[]>>;

export interface GameInterface {
  allPlayers: PlayerId[];
  buttonPlayer: PlayerId;
  gameStacks: PlayerChipMap;
  pots: Pot[];
  currentRound: GameRoundName;
  currentBets: PlayerChipMap;
  allInPlayers: PlayerId[];
  foldedPlayers: PlayerId[];
  settledQueue: PlayerId[];
  actQueue: PlayerId[];
  potResult: PlayerId[][] | null;
  settings: GameSettings;
  minRaise?: number;
  actionHistory: ActionHistory;
}

export class Table implements TableInterface {
  buttonPlayer?: PlayerId;
  players!: string[];
  tableStacks!: PlayerChipMap;
  currentGame: Game | null = null;
  settings!: GameSettings;

  static getEmptyTable() {
    return new Table({
      players: [],
      tableStacks: {},
      currentGame: null,
      settings: {
        smallBlindAmount: 5,
        bigBlindAmount: 10,
        defaultStartingAmount: 1000,
        strictMinRaise: true,
      },
    });
  }

  fromJSON(s: string) {
    Object.assign(this, JSON.parse(s));
    if (this.currentGame)
      this.currentGame = new Game(this.currentGame);
  };
  toJSON() { return this; }
  copy() { return new Table(JSON.parse(JSON.stringify(this))); }

  constructor(obj: TableInterface) {
    Object.assign(this, obj);
    if (this.currentGame)
      this.currentGame = new Game(this.currentGame);
  }

  sanityCheck() {
    Assert.expect(this.settings).beTruthy();
    Assert.expect(this.tableStacks).beTruthy();
    Assert.expect(this.players).beTruthy();
  }

  canStartNewGame() {
    return this.players.filter(p => this.tableStacks[p] > 0).length > 1;
  }

  initializeNewGame() {
    const players = this.players.filter(p => this.tableStacks[p] > 0);

    Assert.expect(players.length).ge(2, 'Cannot start game, make sure canStartNewGame is called');

    this.buttonPlayer ??= players[0];

    const sb = findPlayer(players, this.buttonPlayer, 'sb');
    const bb = findPlayer(players, this.buttonPlayer, 'bb');
    const gameStacks = R.pick(players, this.tableStacks);

    const g = new Game({
      allPlayers: players,
      buttonPlayer: this.buttonPlayer,
      gameStacks,
      pots: [],
      currentRound: 'pre-flop',
      currentBets: {},
      allInPlayers: [],
      foldedPlayers: [],
      settledQueue: [],
      actQueue: generateQueueForRound(players, players, this.buttonPlayer, 'pre-flop-before-auto-blinds'),
      potResult: null,
      settings: this.settings,
      actionHistory: {},
    });

    // Bet small blind
    Assert.expect(g.currentPlayer).eq(sb);
    g.act({ type: 'blinds', sbBb: 'sb' });

    // Bet big blind
    Assert.expect(g.currentPlayer).eq(bb);
    g.act({ type: 'blinds', sbBb: 'bb' });

    return g;
  }

  addNewPlayer(playerId: PlayerId) {
    if (!this.players.includes(playerId))
      this.players.push(playerId);
    this.tableStacks[playerId] ??= this.settings.defaultStartingAmount;
  }

  private absorbGame(game: Game) {
    const winnerChipMap = game.getWinnerChipMap();

    this.tableStacks = mergePlayerChipMap(game.gameStacks, winnerChipMap);
    this.tableStacks = mergePlayerChipMap(generateObjectWithKeysAndValue(this.players, 0), this.tableStacks);

    const playersWithChips = this.players.filter(p => this.tableStacks[p] > 0);
    const arr = [...playersWithChips, ...playersWithChips];
    this.buttonPlayer = arr[arr.indexOf(game.buttonPlayer) + 1];
  }

  completeCurrentGame() {
    if (this.currentGame === null) { throw new Error('There must be a current game') };
    this.absorbGame(this.currentGame);
    this.currentGame = null;
  }
}

export class Game implements GameInterface {
  allPlayers!: PlayerId[];
  buttonPlayer!: PlayerId;
  gameStacks!: PlayerChipMap;
  pots!: Pot[];
  currentRound!: GameRoundName;
  currentBets!: PlayerChipMap;
  allInPlayers!: PlayerId[];
  foldedPlayers!: PlayerId[];
  settledQueue!: PlayerId[];
  actQueue!: PlayerId[];
  potResult: PlayerId[][] | null = null;
  settings!: GameSettings;
  actionHistory: ActionHistory = {};

  minRaise: number = -1;

  fromJSON(s: string) { Object.assign(this, JSON.parse(s)); };
  toJSON() { return this; }
  copy() { return new Game(JSON.parse(JSON.stringify(this))); }

  constructor(obj: GameInterface) {
    Object.assign(this, obj);
    if (this.minRaise === -1) {
      Assert.expect(this.currentRound).eq('pre-flop');
      this.resetMinRaise();
    }

    this.sanityCheck();
  }

  resetMinRaise() {
    this.minRaise = this.settings.bigBlindAmount;
  }

  sanityCheck() {
    const assertUnique = (array: any) => {
      Assert(Array.isArray(array), 'Expected to be array');
      Assert.expect(new Set(array).size).eq(array.length, 'should be unique');
    }
    Assert.expect(this.minRaise).gt(0);
    Assert.expect(this.minRaise).ge(this.settings.bigBlindAmount);
    // allPlayers is unique
    assertUnique(this.allPlayers);
    // allPlayers.length >= 2
    Assert.expect(this.allPlayers.length).ge(2, 'allPlayers size should be >= 2');
    // allPlayers.includes(buttonPlayer)
    Assert(this.allPlayers.includes(this.buttonPlayer), 'buttonPlayer should be in allPlayers');
    // allInPlayers, foldedPlayers, settledQueue, actQueue are unique
    const union = [...this.allInPlayers, ...this.foldedPlayers, ...this.settledQueue, ...this.actQueue];
    assertUnique(union);
    // union of all of them === allPlayer
    Assert(union.length === this.allPlayers.length);
    this.allPlayers.forEach((p) => Assert(union.includes(p)));

    // gameStack >= 0
    Object.entries(this.gameStacks).forEach(([key, value]) => {
      Assert(this.allPlayers.includes(key), `${key} is not a valid player`);
      Assert.expect(value).ge(0);
      if (value === 0) {
        Assert(this.allInPlayers.includes(key), `${key} has 0 gameStack but is not in allInPlayers`);
      }
    });
    // in allInPlayers <=> gameStack == 0
    // Disabled due to the effective stack size change
    // this.allPlayers.forEach(p => {
    //   if (this.allInPlayers.includes(p))
    //     Assert.expect(this.gameStacks[p]).eq(0, `allInPlayers should have 0 gameStack ${p}`);
    //   else
    //     Assert.expect(this.gameStacks[p]).gt(0, `non allInPlayers should have > 0 gameStack ${p}`);
    // })


    // pots:
    this.pots.forEach(pot => {
      Assert.expect(pot.amount).gt(0);
      Assert.expect(pot.players.length).gt(0);
      pot.players.forEach(p => Assert(this.allPlayers.includes(p)));
    })

    if (this.currentRound === 'showdown') {
      Assert.expect(this.potResult).beTruthy();
      Assert.expect(this.potResult!.length).eq(this.pots.length);
      if (this.isAllPotsSettled) {
        Assert.expect(this.potResult!.length).eq(this.pots.length);
        this.potResult!.forEach((winners, index) => {
          Assert(winners.length > 0);
          winners.forEach(p => Assert(this.pots[index].players.includes(p)));
        })
      }

      if (this.potResult!.length === 0) {
        Assert.expect(this.pots.length).eq(0);
      }

    } else {
      Assert.expect(this.potResult).beFalsy();
    }
  }

  sanityCheckConservationOfChips(tableStack: PlayerChipMap) {
    const chipsBeganWith: number = R.sum(R.values(tableStack));
    // Total chips amount should stay the same
    const gameStack = R.sum(R.values(this.gameStacks));
    const potsAmount = R.sum(this.pots.map(p => p.amount));
    const currentBetsAmount = R.sum(R.values(this.currentBets));
    const total = gameStack + potsAmount + currentBetsAmount;
    Assert.expect(total).eq(chipsBeganWith);

    if (this.currentRound === 'showdown' && this.isAllPotsSettled) {
      const totalWinnerChips = R.sum(R.values(this.getWinnerChipMap()));
      Assert.expect(currentBetsAmount).eq(0);
      Assert.expect(totalWinnerChips).eq(potsAmount);
      const chipEndWith = totalWinnerChips + gameStack;
      Assert.expect(chipEndWith).eq(chipsBeganWith);
    }
  }

  get currentPlayer(): PlayerId | null {
    if (this.actQueue.length === 0) return null;
    if (this.currentRound === 'showdown') return null;
    return this.actQueue[0];
  }

  get currentPlayerActionOptions(): PlayerActionOptions {
    const playerToAct = this.currentPlayer;
    if (playerToAct === null) return {
      fold: false,
      check: false,
      call: null,
      raise: null,
    };

    const maxBetSoFar = Math.max(...Object.values(this.currentBets), 0);
    const playerBet = this.currentBets[playerToAct] ?? 0;
    const costToCall = maxBetSoFar - playerBet;
    const playerStack = this.gameStacks[playerToAct];

    const result: PlayerActionOptions = {
      fold: true,
      check: false,
      call: null,
      raise: null,
    }

    const currentChipMapExcludingPotsFromPriorRounds = mergePlayerChipMap(this.currentBets, this.gameStacks);
    const maxChips = Math.max(...R.values(currentChipMapExcludingPotsFromPriorRounds));
    const isChipLeader = currentChipMapExcludingPotsFromPriorRounds[this.currentPlayer!] === maxChips;
    const secondChipLeaderAmount = R.values(currentChipMapExcludingPotsFromPriorRounds).toSorted((a, b) => b - a).at(1) ?? 0;
    const diffToSecond = maxChips - secondChipLeaderAmount;

    if (costToCall === 0) {
      // Check or Raise
      result.check = true;
      Assert.expect(this.minRaise).eq(this.settings.bigBlindAmount);

      if (secondChipLeaderAmount !== 0)
        if (isChipLeader)
          result.raise = { baseAmount: 0, minAmount: Math.min(this.minRaise, playerStack - diffToSecond), maxAmount: playerStack - diffToSecond };
        else
          result.raise = { baseAmount: 0, minAmount: Math.min(this.minRaise, playerStack), maxAmount: playerStack };

    } else {
      result.call = { amount: costToCall, isAllIn: false };

      if (isChipLeader)
        result.raise = { baseAmount: costToCall, minAmount: Math.min(this.minRaise, playerStack - diffToSecond - costToCall), maxAmount: playerStack - diffToSecond - costToCall };
      else
        result.raise = { baseAmount: costToCall, minAmount: Math.min(this.minRaise, playerStack - costToCall), maxAmount: playerStack - costToCall };

      if (result.raise.minAmount <= 0 || result.raise.minAmount >= result.raise.maxAmount) {
        result.raise = null;
        result.call.isAllIn = true;
      }

      if (playerStack <= costToCall) {
        result.raise = null;
        result.call = { amount: playerStack, isAllIn: true }; // Must all in to call
      }
    }
    return result;
  }

  act(action: PlayerAction) {
    Assert.expect(this.currentRound).ne('showdown');
    Assert.expect(this.actQueue).beNonEmpty();

    const actionOptions = action.type === 'blinds' ? {
      fold: false,
      check: false,
      call: null,
      raise: null,
    } : this.currentPlayerActionOptions;
    const currentPlayer = this.actQueue.shift()!;
    const currentBet = this.currentBets[currentPlayer] ??= 0;
    let newCurrentBet = currentBet;
    const currentStack = this.gameStacks[currentPlayer];
    let newCurrentStack = currentStack;

    if (action.type !== 'blinds') {
      Assert.expect(actionOptions[action.type]).beTruthy();
    }

    this.actionHistory[this.currentRound] ??= [];
    this.actionHistory[this.currentRound]!.push({ player: currentPlayer, action, actionOptions });

    if (action.type === 'fold') {
      // Remove this player from all pots
      this.pots.forEach(pot => { pot.players = pot.players.filter(p => p !== currentPlayer); });
      this.foldedPlayers.push(currentPlayer);

    } else if (action.type === 'check') {
      this.settledQueue.push(currentPlayer);

    } else if (action.type === 'call') {
      newCurrentStack = currentStack - actionOptions.call!.amount;
      newCurrentBet = currentBet + actionOptions.call!.amount;
      if (actionOptions.call!.isAllIn) {
        this.allInPlayers.push(currentPlayer);
      } else {
        this.settledQueue.push(currentPlayer);
      }

    } else if (action.type === 'raise' || action.type === 'blinds') {
      let amountToGoIntoPot: ChipAmount = -999;
      if (action.type === 'raise') {
        Assert.expect(actionOptions.raise).beTruthy();

        Assert.expect(action.raisingAmount).ge(actionOptions[action.type]!.minAmount);
        Assert.expect(action.raisingAmount).le(actionOptions[action.type]!.maxAmount);
        if (actionOptions.raise?.minAmount !== actionOptions.raise?.maxAmount) {
          // Raising amount must be at least minRaise, unless it's all-in
          Assert.expect(action.raisingAmount).ge(this.minRaise);
        }

        if (this.settings.strictMinRaise) {
          this.minRaise = Math.max(action.raisingAmount + actionOptions.raise!.baseAmount, this.minRaise);
        }

        amountToGoIntoPot = actionOptions.raise!.baseAmount + action.raisingAmount;
      }

      else if (action.type === 'blinds') {
        amountToGoIntoPot = Math.min(currentStack, action.sbBb === 'sb' ? this.settings.smallBlindAmount : this.settings.bigBlindAmount);
      }

      else { Assert.never(); }

      Assert.expect(amountToGoIntoPot).gt(0);
      newCurrentStack = currentStack - amountToGoIntoPot;
      newCurrentBet = currentBet + amountToGoIntoPot;

      Assert.expect(newCurrentStack).ge(0);

      this.actQueue.push(...this.settledQueue);
      this.settledQueue = [];

      // `newCurrentStack === 0` alone does not work all the time because of the "effective stack"
      const isAllIn = newCurrentStack === 0 || (action.type === 'raise' && action.raisingAmount === actionOptions.raise!.maxAmount);
      if (isAllIn) {
        this.allInPlayers.push(currentPlayer);
      } else {
        if (action.type === 'blinds')
          this.actQueue.push(currentPlayer);
        else
          this.settledQueue.push(currentPlayer);
      }

    } else {
      Assert.never();
    }

    this.currentBets[currentPlayer] = newCurrentBet;
    this.gameStacks[currentPlayer] = newCurrentStack;

    // If there is only one player that has not folded, act "check" for it.
    if (this.allPlayers.length - this.foldedPlayers.length === 1) {
      // And if it's a raise, the "raise" was not small blind placed by system
      // MARK: variable logic for edge case?
      // if (action.type !== 'blinds' || action.sbBb !== 'sb') {
      if (this.actQueue.length === 1) {
        this.settledQueue.push(this.actQueue.pop()!);
      }
      // }
    }

    if (this.actQueue.length === 0) {
      let nextRound: GameRoundName;
      const newActQueue = generateQueueForRound(this.allPlayers, this.settledQueue, this.buttonPlayer, 'post-pre-flop');
      const inGamePlayers = [...this.allInPlayers, ...this.settledQueue];
      const foldedPlayers = this.foldedPlayers;
      Assert.expect(inGamePlayers.length + foldedPlayers.length).eq(this.allPlayers.length);

      let inGameBets: Record<PlayerId, ChipAmount> = {}, foldedBets: Record<PlayerId, ChipAmount> = {};
      for (const [player, amount] of Object.entries(this.currentBets)) {
        if (amount === 0) continue;
        if (inGamePlayers.includes(player)) {
          inGameBets[player] = amount;
        } else {
          foldedBets[player] = amount;
        }
      }

      this.currentBets = {};

      const newPots: Record<string, number> = {};

      while (true) {
        const minimumBet = R.reduce<number, number>(R.min, Infinity, R.values(inGameBets));
        if (minimumBet === Infinity) break;
        const newInGameBets: typeof inGameBets = {};
        let accumulated = 0;
        for (const [player, bet] of Object.entries(inGameBets)) {
          const newBet = bet - minimumBet;
          if (newBet > 0) {
            newInGameBets[player] = newBet;
            accumulated += minimumBet;
          } else if (newBet === 0) {
            accumulated += minimumBet;
          } else {
            Assert.never('newBet should always be non-negative. player=' + player);
          }
        }

        Assert.expect(accumulated % minimumBet).eq(0);

        const newFoldedBets: typeof foldedBets = {};
        for (const [player, bet] of Object.entries(foldedBets)) {
          const newBet = Math.max(0, bet - minimumBet);
          if (newBet > 0) {
            newFoldedBets[player] = newBet;
            accumulated += minimumBet;
          } else {
            accumulated += bet;
          }
        }

        const oldBets = R.sum(R.map(R.values, [inGameBets, foldedBets]).flat());
        const newBets = R.sum(R.map(R.values, [newInGameBets, newFoldedBets]).flat());
        Assert.expect(oldBets - newBets).eq(accumulated);
        Assert.expect(accumulated).gt(0);

        const involvedPlayersStr = JSON.stringify(R.keys(inGameBets).sort());
        Assert.expect(involvedPlayersStr in newPots).beFalsy();
        newPots[involvedPlayersStr] = accumulated;

        inGameBets = newInGameBets;
        foldedBets = newFoldedBets;
      }

      if (R.sum(R.values(foldedBets)) > 0) {
        this.gameStacks = mergePlayerChipMap(this.gameStacks, foldedBets);
      }

      for (const [involvedPlayersStr, amount] of Object.entries(newPots)) {
        const existingPotIndex = this.pots.findIndex((pot) => JSON.stringify(pot.players) === involvedPlayersStr);

        if (existingPotIndex >= 0) {
          this.pots[existingPotIndex].amount += amount;
        } else {
          this.pots.push({
            players: reorderItemAsTheyAppearIn(JSON.parse(involvedPlayersStr), this.allPlayers),
            amount,
          })
        }
      }

      if (newActQueue.length === 0 || newActQueue.length === 1) {
        nextRound = 'showdown';
      } else {
        this.settledQueue = [];
        this.actQueue = newActQueue;
        const gameRounds: GameRoundName[] = ["pre-flop", "flop", "turn", "river", "showdown"];
        nextRound = gameRounds[gameRounds.indexOf(this.currentRound) + 1];
        Assert(nextRound !== undefined && nextRound !== 'pre-flop');
      }

      if (nextRound === 'showdown') {
        this.potResult = this.pots.map((pot) => pot.players.length === 1 ? [pot.players[0]] : []);
      }

      if (nextRound !== this.currentRound) {
        this.resetMinRaise();
      }

      this.currentRound = nextRound;
    }

    this.sanityCheck();
  }

  toString(): string {
    let result = "";
    // Pots
    if (this.pots.length === 1)
      result += `Pot: ${this.pots[0].amount}\n`;
    else {
      result += `Pots:\n`;
      for (const p of this.pots)
        result += `- ${p.amount} (${p.players.join(', ')})\n`;
    }

    // Round
    result += `Round: ${this.currentRound}\n`;
    result += `\n`;

    // Players
    for (const p of this.settledQueue) result += `${p} (${this.gameStacks[p]}): ${this.currentBets[p] ?? 0}\n`;
    result += '> ';
    for (const p of this.actQueue) result += `${p} (${this.gameStacks[p]}): ${this.currentBets[p] ?? 0}\n`;

    return result;
  }

  isPotSettled(index: number) {
    // TODO: Check if winner makes sense across different pots (not sure if this is needed)
    return this.potResult![index].length > 0;
  }

  get isAllPotsSettled() {
    for (let i = 0; i < this.pots.length; i++) {
      if (!this.isPotSettled(i))
        return false;
    }
    return true;
  }

  get totalPotAmountPlusCurrentBets() {
    return R.sum(this.pots.map(p => p.amount)) + R.sum(R.values(this.currentBets));
  }

  getWinnerChipMap(): PlayerChipMap {
    Assert.expect(this.isAllPotsSettled).beTruthy();
    let winnerChipMap: PlayerChipMap = {};

    this.potResult!.forEach((winners, index) => {
      const originalPot = this.pots[index];
      const potChipMap = splitChipIntoInteger(originalPot.amount, winners);
      winnerChipMap = mergePlayerChipMap(winnerChipMap, potChipMap);
    });

    return winnerChipMap;
  }

  nextPlayer(action: PlayerAction) {
    const g = this.copy();
    g.act(action);
    return g.currentPlayer;
  }
}
