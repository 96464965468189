import React, { ReactNode } from "react";
import { PLAYER_SIZE, portalForIf } from "../types";

import { Table } from "../../../models/poker";
import { Sprit } from "../../components";
import { PlayerCardForSetup } from "../PlayerCard";

import { Box, Point } from '@flatten-js/core';
import { arrayReorder, generateObjectWithKeysAndValue } from "../../../models/helpers";


import { SceneProps, SceneInterface } from '../types'
import { SpritId, SpritView } from "../SpritView";
import { setQueryParam } from "../../GamePage";

import Check from '@mui/icons-material/Check';
import { TableSettings } from "./TableSettings";
import { TableQRCode } from "./TableQRCode";
import { PlayerEdit } from "./PlayerEdit";
import { GlobalSettings } from "../GlobalSettings";
import { CentralManager } from "../CentralManager";
import { ServerProxyLocal } from "../../../models/server";
import { PokerRulesOverlay } from "../PokerRulesOverlay";

interface State {
  sceneLoaded: boolean,
  playerPositionText: ReactNode,
  newPlayerTextInput: string,
  overlay: 'table-settings' | 'player-edit' | 'settings' | 'qr-code' | 'global' | 'poker-rules' | null,
}

export function layout(type: 'player' | 'insert', items: Sprit[], canvas: Box, smooth: boolean, emptySelf: boolean) {
  const COUNT = items.length;
  const RADIUS_X = canvas.width / 2 - PLAYER_SIZE.x / 2;
  const RADIUS_Y = canvas.height / 2 - PLAYER_SIZE.y / 2;
  const angleOffset = (type === 'player' ? 0 : 1) + (emptySelf ? 1 : 0);
  return Promise.all(
    items.map((sprit, i) => {
      const x = RADIUS_X * Math.cos(Math.PI * (angleOffset + i * 2) / COUNT + Math.PI / 2)
      const y = RADIUS_Y * Math.sin(Math.PI * (angleOffset + i * 2) / COUNT + Math.PI / 2)
      const offsetX = canvas.center.x
      const offsetY = canvas.center.y
      return sprit.C(smooth, new Point(x + offsetX, y + offsetY), 'easeInOut');
    })
  );
}

function layoutSimple(players: string[], sv: SpritView, canvas: Box, emptySelf: boolean) {
  const playerSprits = players.map(p => sv.get({ player: p }));
  const insertSprits = players.map(p => sv.get({ insert: p }));
  return Promise.all([
    layout('player', playerSprits, canvas, true, emptySelf),
    layout('insert', insertSprits, canvas, false, emptySelf),
  ]);
}

export class TableView extends React.Component<SceneProps, State> implements SceneInterface {
  canvas: Box;
  table: Table;
  cm: CentralManager;
  sv: SpritView;

  setStateAsync = (state: any) => new Promise<void>((resolve) => this.setState(state, resolve));

  state: State = {
    sceneLoaded: false,
    playerPositionText: null,
    newPlayerTextInput: '',
    overlay: null,
  }

  get t() {
    return this.table;
  }

  get playerId() { return this.props.playerId ?? null; }

  get playerId2() {
    return this.table.players.includes(this.playerId ?? '') ? this.playerId : null;
  }

  get linkToJoin() {
    return `${window.location.origin}/play/${this.props.tableId}`;
  }

  constructor(props: SceneProps) {
    super(props);
    this.canvas = props.initialCanvas;
    this.table = props.initialTable;
    this.cm = props.cm;
    this.sv = props.spritView;
  }

  newPlayerBoxSprit!: Sprit;
  playerSprits: Sprit[] = [];
  insertSprits: Sprit[] = [];
  sceneLoad = async () => { }

  sceneLoad_ = async () => {
    this.sv.batchStart();

    this.newPlayerBoxSprit = this.sv.findOrCreate(
      { name: 'new-player-box' },
      {
        size: PLAYER_SIZE,
        ifNew: sprit => { sprit.cAt(this.canvas.center) },
        initialVariant: this.playerId ? 'hidden' : 'visible',
        variants: {
          hidden: {
            opacity: 0,
            scale: 0,
          },
          visible: {
            opacity: 1,
            scale: 1,
          },
        },
      }
    )

    this.playerSprits = arrayReorder2(this.t.players, this.playerId2).map(p => {
      const x = this.sv.findOrCreate(
        { player: p },
        {
          size: PLAYER_SIZE,
          variants: playerVariants,
          initialVariant: 'normal',
          dragHandler: (newCenter, hasEnded) => this.onDrag(p, newCenter, hasEnded),
          ifNew: sprit => { sprit.cAt(this.canvas.center) },
        }
      );
      x.zIndex = 100;
      return x;
    });

    this.insertSprits = arrayReorder2(this.t.players, this.playerId2).map(p => {
      const x = this.sv.findOrCreate(
        { insert: p },
        {
          size: { x: 25, y: 25 },
          variants: insertVariants,
          initialVariant: 'hidden',
        }
      );
      x.additionalConstantStyles = {
        borderRadius: '50%',
      }
      x.zIndex = 90;
      return x;
    });

    const untouched = await this.sv.batchEnd();
    Object.values(untouched).forEach(s => {
      this.sv.remove(s.id);
    });
    await this.setStateAsync({ sceneLoaded: true });
  };

  showNewPlayerBoxAsNecessary = async () => {
    if (this.props.playerId) {
      if (this.t.players.includes(this.props.playerId)) {
        await this.newPlayerBoxSprit.animateToVariant('hidden');
      } else {
        await this.newPlayerBoxSprit.animateToVariant('visible');
      }

    } else {
      await this.newPlayerBoxSprit.animateToVariant('visible');
    }

    await this.newPlayerBoxSprit.moveC(this.canvas.center);
  }

  sceneLayout = async (canvas: Box, smooth: boolean) => {
    this.canvas = canvas;

    this.setStateAsync({ sceneLoaded: false });
    await this.sceneLoad_();

    await Promise.all([
      layout('player', this.playerSprits, this.canvas, smooth, this.playerId2 === null),
      layout('insert', this.insertSprits, this.canvas, false, this.playerId2 === null),
      this.showNewPlayerBoxAsNecessary(),
    ])
  }

  sceneUnload = async () => {
    await Promise.all(
      [
        ...this.t.players.map(p => this.sv.remove({ insert: p })),
        this.sv.remove({ name: 'new-player-box' }),
      ]
    );
    await this.setStateAsync({ sceneLoaded: false });
  };

  onTableUpdate = async (table: Table) => {
    this.table = table;
    await this.sceneLayout(this.canvas, true);
  }

  async onDrag(player: string, newCenter: Point, hasEnded: boolean) {
    const target = getTarget(newCenter, this.sv, this.canvas, { self: player, neighbor: player });

    const toPlay: (Promise<any> | any)[] = [];

    // all player should by default be normal
    // all insert should by default be visible
    const playerSpritsVariants = generateObjectWithKeysAndValue(this.t.players, 'normal');
    let insertSpritsVariants = generateObjectWithKeysAndValue(this.t.players, 'visible');
    let newText: ReactNode = <span className="font-semibold" style={{ color: COLOR_MOVE }}>Move to a gap or a player to change seats</span>

    const insertIdLeft = getInsertIdAroundPlayer(this.t.players, player).left;
    const insertIdRight = getInsertIdAroundPlayer(this.t.players, player).right;


    if (hasEnded) {
      let isDeleting = false;
      const newPlayers = [...this.t.players];
      newText = null;
      if (target === 'trash') {
        newPlayers.splice(newPlayers.indexOf(player), 1);
        isDeleting = true;

      } else if (target === null) {
        // Do nothing

      } else if ('insert' in target) {
        if (target.insert === insertIdLeft || target.insert === insertIdRight) {
          // do nothing
        } else {
          const i = newPlayers.indexOf(player);
          let j = newPlayers.indexOf(target.insert);
          if (j < i) j += 1;
          newPlayers.splice(i, 1);
          newPlayers.splice(j, 0, player);
        }

      } else if ('player' in target) {
        const i = newPlayers.indexOf(player);
        const j = newPlayers.indexOf(target.player);
        newPlayers[i] = target.player;
        newPlayers[j] = player;

      } else {
        playerSpritsVariants[player] = 'normal';
      }

      insertSpritsVariants = generateObjectWithKeysAndValue(this.t.players, 'hidden');

      const newT = this.t;
      newT.players = newPlayers;
      if (isDeleting && newT.buttonPlayer === player)
        newT.buttonPlayer = newPlayers[0];

      const isOrWillBeEmptySelf = this.playerId2 === null || (isDeleting && this.playerId2 === player);
      toPlay.push(layoutSimple(arrayReorder2(newPlayers, this.playerId2), this.sv, this.canvas, isOrWillBeEmptySelf));
      toPlay.push(this.cm.triggerTableUpdate(newT, 'update player order'));

    } else { // has not ended
      playerSpritsVariants[player] = 'dragging';

      insertSpritsVariants = generateObjectWithKeysAndValue(this.t.players, 'visible');
      insertSpritsVariants[insertIdLeft] = 'hidden';
      insertSpritsVariants[insertIdRight] = 'hidden';

      if (target === null) {
        // Do nothing
      }

      else if (target === 'trash') {
        playerSpritsVariants[player] = 'toBeDeleted';
        newText = <span style={{ color: COLOR_REMOVE }}>Removing <span className="player">{player}</span></span>
      }

      else if ('insert' in target) {
        if (insertIdLeft === target.insert || insertIdRight === target.insert) {
          // do nothing
        } else {
          insertSpritsVariants[target.insert] = 'active';
          newText = <span style={{ color: COLOR_MOVE }}>Moving <span className="player">{player}</span></span>
        }
      }

      else if ('player' in target) {
        newText = <span style={{ color: COLOR_MOVE }}>Swapping <span className="player">{player}</span> and <span className="player">{target.player}</span></span>
        playerSpritsVariants[target.player] = 'toBeReplaced';
      }

      else { console.warn('Unhandled target', target); }
    }

    for (const player in playerSpritsVariants) {
      toPlay.unshift(this.sv.get({ player }).animateToVariant(playerSpritsVariants[player]));
      toPlay.unshift(this.sv.get({ insert: player }).animateToVariant(insertSpritsVariants[player]));
    }

    this.setState({ playerPositionText: newText });

    await Promise.all(toPlay);
  }

  startGame = () => {
    const g = this.t.initializeNewGame();
    this.t.currentGame = g;
    this.cm.triggerTableUpdate(this.t, 'new game');
  }

  randomizePlayers = () => {
    const newPlayers = [...this.t.players];
    for (let i = newPlayers.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newPlayers[i], newPlayers[j]] = [newPlayers[j], newPlayers[i]];
    }
    this.t.players = newPlayers;
    this.cm.triggerTableUpdate(this.t, `randomize players`);
  }

  newPlayerJoinClicked = async () => {
    const newPlayer = this.state.newPlayerTextInput.trim();
    if (newPlayer.length === 0) return;

    if (this.cm.props.singlePhoneMode) {
      // Do this in single screen mode
      await this.setStateAsync({ newPlayerTextInput: '' });

    } else {
      // Not doing this if in single screen mode
      setQueryParam('player', newPlayer);
      await this.cm.updatePlayerId(newPlayer);
    }

    if (this.t.players.includes(newPlayer)) {
      this.onTableUpdate(this.table);

    } else {
      this.t.addNewPlayer(newPlayer);
      this.cm.triggerTableUpdate(this.t, `${newPlayer} joins`);
    }
  }

  showOverlay = async (view: State['overlay']) => {
    if (view) {
      await this.setStateAsync({ overlay: view });
      await this.cm.setOverlayVisibility(true);
    } else {
      await this.cm.setOverlayVisibility(false);
    }
  }

  resetLocalTable = () => {
    if (confirm('Are you sure to completely reset this local table?')) {
      new ServerProxyLocal().createTable({ tableId: this.cm.props.tableId })
      window.location.reload();
    }
  }

  render() {
    const { GlobalButton } = this.props;
    const portalFor = portalForIf(this.props.uniqueViewId, this.state.sceneLoaded);

    return [
      portalFor(this.newPlayerBoxSprit)(
        <div className="w-full h-full flex flex-col gap-1">
          <div className="hint-text">{this.cm.props.singlePhoneMode ? 'name a player to join' : 'name yourself to join'}</div>
          <input
            className={`new-player-input player player-card-player-font-size ${this.state.newPlayerTextInput.length === 0 && !this.cm.props.singlePhoneMode && 'invalid'}`}
            // Using uniqueViewId as a hack to check if we are running in a "managed" mode
            autoFocus={!!this.props.uniqueViewId}
            type="text" autoComplete='off' autoCorrect="off" autoCapitalize="off" spellCheck={false}
            value={this.state.newPlayerTextInput}
            onChange={e => this.setState({ newPlayerTextInput: e.target.value })}
            onKeyUp={e => e.key === 'Enter' && this.newPlayerJoinClicked()}
          />
          <button className="button3 w-full green" onClick={() => this.newPlayerJoinClicked()}>join</button>
        </div>
      ),
      portalFor('view-before')(
        <div className="view-before">
          <div className="text-and-settings">
            <GlobalButton onClick={() => this.showOverlay('global')} />
            <div className="prompt-text">
              {
                this.state.playerPositionText
                ??
                <span>
                  {
                    this.cm.props.singlePhoneMode ?
                      'Single Phone Mode' :
                      <>
                        goto <span className="emphasize">{window.location.origin.replace(/^https?:\/\//, '')}</span> and
                        enter <span className="emphasize">{this.props.tableId.split('+')[0]}</span> to join
                      </>
                  }
                </span>
              }
            </div>
          </div>
          {
            this.state.playerPositionText === null ?
              <>
                {
                  this.cm.props.singlePhoneMode ?
                    <div className="button3 w-full col-span-2"
                      onClick={this.resetLocalTable}>
                      reset local table
                    </div>
                    :
                    <>
                      <CopyLinkButton linkToJoin={this.linkToJoin} />
                      <div className="button3 w-full" onClick={() => this.showOverlay('qr-code')}>show qr code</div>
                    </>
                }
              </>
              :
              <div className="button3 w-full col-span-2 red">disconnect & remove from table</div>
          }
        </div>
      )
      ,
      portalFor('view-after')(
        <div className="grid grid-cols-2 gap-2 px-2 justify-items-center">
          <div className="button3 w-full" onClick={() => this.showOverlay('table-settings')}>blinds: {this.t.settings.smallBlindAmount}/{this.t.settings.bigBlindAmount}</div>
          <div className="button3 w-full" onClick={() => this.showOverlay('player-edit')}>edit players</div>
          {/* <div className="button3 w-full" onClick={() => this.randomizePlayers()}>randomize players</div> */}
          <div className={`button3 green w-full col-span-2 ${this.t.canStartNewGame() || 'disabled'}`} onClick={() => this.startGame()}>start game</div>
        </div>
      )
      ,
      ...this.t.players.map(player =>
        portalFor({ player })(
          <PlayerCardForSetup
            table={this.t}
            player={player}
          />
        )
      )
      ,
      ...this.t.players.map(player =>
        portalFor({ insert: player })(
          <div></div>
        )
      )
      ,
      portalFor('overlay')(
        <>
          {
            this.state.overlay === 'table-settings' &&
            <TableSettings
              close={() => this.showOverlay(null)}
              initialSettings={this.table.settings}
              onSave={(newSettings) => { this.table.settings = newSettings; this.cm.triggerTableUpdate(this.table, 'edit table settings') }}
            />
          }

          {
            this.state.overlay === 'qr-code' &&
            <TableQRCode
              close={() => this.showOverlay(null)}
              linkToJoin={this.linkToJoin}
            />
          }

          {
            this.state.overlay === 'player-edit' &&
            <PlayerEdit
              close={() => this.showOverlay(null)}
              initialTable={this.table.copy()}
              onSave={(newTable) => { this.cm.triggerTableUpdate(newTable, 'edit players') }}
            />
          }

          {
            this.state.overlay === 'global' &&
            <GlobalSettings sharedOptions={this.props.globalSettings} close={() => this.showOverlay(null)} tmpShowPoker={() => this.showOverlay('poker-rules')} />
          }

          {
            this.state.overlay === 'poker-rules' &&
            <PokerRulesOverlay close={() => this.showOverlay(null)} />
          }
        </>
      )
    ];
  }

}


const COLOR_MOVE = '#0ea5e9';
const COLOR_REMOVE = '#ef4444';

const insertVariants = {
  active: {
    opacity: 1,
    scale: 1.1,
    borderColor: COLOR_MOVE,
    borderWidth: 12.5 * 1.1,
  },
  hidden: {
    opacity: 0,
    scale: 1,
    borderColor: '#888888',
    borderWidth: 0,
  },
  visible: {
    opacity: 0.9,
    scale: 1,
    borderColor: '#888888',
    borderWidth: 2,
  },
};

const playerVariants = {
  normal: {
    borderColor: '#64748b',
    borderWidth: 0,
    opacity: 1,
    scale: 1,
  },
  // placeholder: {
  //   borderColor: 'rgb(107 114 128)',
  //   borderWidth: '2px',
  //   opacity: 0.85,
  // },
  dragging: {
    borderColor: '#64748b',
    borderWidth: 5 / 1.05,
    opacity: 0.75,
    scale: 1.05,
  },
  toBeReplaced: {
    borderColor: COLOR_MOVE,
    borderWidth: 5,
    opacity: 1,
    scale: 1,
  },
  toBeDeleted: {
    borderColor: COLOR_REMOVE,
    borderWidth: 5 / 1.05,
    opacity: 0.75,
    scale: 1.05,
  },
}


function getTarget(point: Point, sv: SpritView, canvas: Box, info: { self: string, neighbor: string }): { player: string } | { insert: string } | 'trash' | null {
  const sprits = sv.getAll();

  let shortestDistance = Infinity;
  let target: SpritId | null = null;

  if (point.y < canvas.ymin) return 'trash';

  for (const sprit of sprits) {
    const id = sprit.id;
    if (id === null) throw new Error('Sprit id is null');
    if ('player' in id || 'insert' in id) {
      if ('player' in id && id.player === info.self) continue;
      if ('insert' in id && id.insert === info.self) continue;
      if ('insert' in id && id.insert === info.neighbor) continue;

      const distance = sprit.box.center.distanceTo(point)[0];
      if (distance < shortestDistance) {
        shortestDistance = distance;
        target = id;
      }
    }
  }

  if (shortestDistance > 50) return null;

  if (target === null) return null;

  return target;
}

function getInsertIdAroundPlayer(players: string[], player: string) {
  return {
    left: player,
    right: players.at(players.indexOf(player) - 1 % players.length)!,
  }
}


function arrayReorder2(players: string[], currentPlayer: string | null) {
  if (!currentPlayer) return players;
  return arrayReorder(players, currentPlayer);
}

function CopyLinkButton({ linkToJoin }: { linkToJoin: string }) {

  const [justCopied, setJustCopied] = React.useState(false);

  const copyLink = () => {
    navigator.clipboard.writeText(linkToJoin);
    setJustCopied(true);
  }

  React.useEffect(() => {
    if (justCopied) {
      const timeout = setTimeout(() => setJustCopied(false), 2000);
      return () => clearTimeout(timeout);
    }
  }, [justCopied]);

  if (justCopied)
    return (
      <div className="button3 w-full green">
        <span className="opacity-0"><Check /></span>
        link copied
        <span className="opacity-100 -translate-y-[2px] scale-120"><Check /></span>
      </div>)

  return (
    <div className="button3 w-full" onClick={copyLink}>
      <span className="opacity-0"><Check /></span>
      copy link
      <span className="opacity-0"><Check /></span>
    </div>
  )
}

