import { useParams } from "react-router"
import Inspector from "./components/Inspector"

export const InspectorPage = () => {
  const tableId = useParams().tableId!;
  return (
    <Inspector
      tableId={tableId}
      useServer='remote'
    />
  )
}
