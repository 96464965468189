import { FC } from "react";
import DisplayContainer from './display/DisplayContainer';
import ClientController, { getQueryParam } from './display/ClientController';
import { useMediaQuery } from "@mui/material";
import React from "react";
import { useNavigate, useParams } from "react-router";

export function setQueryParam(name: string, value: string) {
  if (getQueryParam(name, '') === value) return;
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(name, value);

  window.history.pushState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
}

const JoinPage: FC = () => {
  const tableId = useParams().tableId!;
  const forceFull = !!getQueryParam('full', '');

  const isInLandscape = useMediaQuery('(orientation: landscape)');
  const isTooWide = useMediaQuery('(min-width: 600px)');
  const showGutter = (isTooWide || isInLandscape) && !forceFull;

  const width = showGutter ? Number.parseInt(getQueryParam('w', '500')) : undefined;
  const height = showGutter ? Number.parseInt(getQueryParam('h', '800')) : undefined;
  const singlePhoneMode = !!getQueryParam('singlePhoneMode', '');

  React.useEffect(() => {
    document.title = `Joining table ${tableId} | vchips.app`
  }, [tableId]);

  React.useEffect(() => {
    if (width)
      setQueryParam('w', width.toString());
    if (height)
      setQueryParam('h', height.toString());
  }, [width, height]);


  React.useEffect(() => {
    if (showGutter) {
      window.dispatchEvent(new Event('vchips_resizeContainerStop'));
    } else {
      window.dispatchEvent(new Event('vchips_resizeContainerStart'));
    }
  }, [showGutter]);

  const navigate = useNavigate();


  return (
    <div className={`w-full h-full ${showGutter && `flex flex-col gap-3 items-center justify-center min-h-screen`}`}>
      {
        showGutter &&
        <div className="text-neutral-500 text-center">
          vchips.app works best upright on your phone
          <div className="underline cursor-pointer" onClick={() => { setQueryParam('full', '1'); window.location.reload(); }}>
            <b>force full screen anyway</b>
          </div>
        </div>
      }
      <DisplayContainer width={width} height={height}>
        <ClientController
          tableId={tableId}
          onTableDNE={() => { navigate('/#table-dne'); }}
          useServer={singlePhoneMode ? 'local' : 'remote'}
        />
      </DisplayContainer>
    </div>
  )
}

export default JoinPage;
