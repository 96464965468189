import { FC } from 'react';
import HomePage from './react/HomePage';
import MultiScreenPage from './react/MultiScreenPage';
import JoinPage from './react/GamePage';
import TestPage from './react/TestPage';
import { BrowserRouter, Routes, Route } from "react-router";
import { InspectorPage } from './react/InspectorPage';


const App: FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/play/:tableId" element={<JoinPage />} />

        <Route path="/tools/demo" element={<MultiScreenPage />} />
        <Route path="/tools/inspect/:tableId" element={<InspectorPage />} />
        <Route path="/tools/test" element={<TestPage />} />

        <Route path="/*" element={<HomePage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
